/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { Helmet } from 'react-helmet'
import BackgroundImg from 'gatsby-background-image'
import { globalHistory } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Components
import Banner from '../components/Home/bannerHome'
import Swiper from 'react-id-swiper'
import Layout from '../components/Layout/index'
import SectionReceitas from '../components/receitas/SectionReceitasPosts/sectionReceitasPosts'
import BannerDiaExpress from '../components/Diaexpress/banner-dia-express'

// icons
import { faMapMarked } from '@fortawesome/free-solid-svg-icons'

import iconIg from '../images/home/instagram.svg'
import carnes from '../images/home/tem-no-dia/carnes-frescas.svg'
import frutas from '../images/home/tem-no-dia/frutas-legumes.svg'
// import mercearia from '../images/home/tem-no-dia/mercearia.svg'
import padaria from '../images/home/tem-no-dia/padaria.svg'
import produtosDia from '../images/home/tem-no-dia/iconeMelhor.svg'
import receitasTodoDia from '../images/home/tem-no-dia/receitas-todo-dia.svg'
import cupom from '../images/home/banner-app/cupom.svg'
import gogolePlay from '../images/home/banner-app/google-play.svg'
import appStore from '../images/home/banner-app/app-store.svg'
import phone from '../images/home/banner-app/phone.svg'
import iconLocal from '../images/home/iconLocal.svg'
import iconLocalDM from '../images/home/iconLocalDM.svg'
import Ilojas from '../images/home/header/icon-lojas.svg'
import logoDesconto from '../images/home/banner-app/logo_clubDIA_230.png'
import iconPin from '../images/home/icon-pin.svg'
// import diaLogo from '../images/home/modalHome/dia_logo_red.svg'
import lupa from '../images/home/modalHome/lupaVermelha.svg'
import moment from 'moment-timezone'
// scss
import * as styles from './styles/index.module.scss'

const SEO = () => (
  <Helmet>
    <html lang="pt-BR" amp />
    <meta charSet="utf-8" />
    <title>DIA Supermercado | Ofertas, Economia e ClubDIA</title>
    <meta name="description" content="Bem-vindo à sua loja de bairro! Oferecemos economia, qualidade e produtos fresquinhos perto de sua casa." />
    <meta name="keywords" content="dia supermercado, ofertas dia, clubdia, dia express" />
    <meta name="author" content="DIA Supermercados" />
  </Helmet>
)

// eslint-disable-next-line no-unused-vars
const InstaSection = ({ posts }) => {
  const swiperParams = {
    slidesPerView: 1.1,
    spaceBetween: 1,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      1000: {
        slidesPerView: 3,
        spaceBetween: 1
      },
      1100: {
        slidesPerView: 3,
        spaceBetween: 1
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 1
      }
    }
  }
  return (
    <section className={`container my-5 ${styles.swiperHome}`}>
      <header className="pb-4 d-flex justify-content-center align-items-center">
        <h2 className="text-center text-red">
          <img src={iconIg} className="w-15 pr-3" alt="icone instagram" />
          Insta do Dia
        </h2>
      </header>
      <div className={styles.swiperInsta}>
        <Swiper {...swiperParams}>
          {
            posts.edges.map((post, index) => {
              return (
                <div key={`${index}`} className="pr-3 pl-3">
                  <a href={post.node.permalink} target="_blank" rel="noopener noreferrer">
                    <GatsbyImage
                      image={post.node.localFile.childImageSharp.gatsbyImageData}
                      className="w-100 dia-rounded"
                      alt="Veja nosso Post no Instagram" />
                  </a>
                </div>
              )
            })
          }
        </Swiper>
      </div>
    </section>
  )
}

const OfertasHome = (data) => {
  const [hasSelectedStore, setHasSelectedStore] = useState(data.current)
  const [hasOfertas, setHasOfertas] = useState()
  const [offers, setOffers] = useState([])
  const [findStore, setFindStore] = useState(false)

  const swiperParams = {
    slidesPerView: 1.1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      1000: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      1100: {
        slidesPerView: 4,
        spaceBetween: 1
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 1
      }
    }
  }

  useEffect(() => {
    handleTabloide(hasSelectedStore)
  }, [hasSelectedStore])

  const handleTabloide = (current) => {
    if (current && current.tariff) {
      const tabloide = data.tabloide.nodes.find(tabloide => tabloide.tariff && (tabloide.tariff.id === current.tariff.id))
      if (tabloide !== undefined) {
        setHasOfertas(true)
        const tb = tabloide.offer.filter(tb => tb.format === 4)
        setOffers(tb)
      } else {
        setHasOfertas(false)
        setOffers([])
      }
    }
  }

  return (
    <>
      <section className={`container my-3 ${styles.swiperHome}`}>
        {
          hasSelectedStore
            ? hasOfertas ? (
              <>
                <header className="pb-2 d-lg-flex justify-content-center align-items-center">
                  <h2 className="text-center text-red mb-0 font-size-32px">
                    Ofertas da Semana
                  </h2>
                  <div className="position-absolute w-75">
                    <div className="float-right position-relative d-lg-flex d-none">
                      <img src={iconLocal} alt="Icone de Localidade" className="lightMode mr-2" />
                      <img src={iconLocalDM} alt="Icone de Localidade" className="darkMode mr-2" />
                      <p className="font-size-12 font-Yanone"><b>{hasSelectedStore.name}</b>
                        <Link to='/lojas/' className="text-dark bg-transparent border-0 "><u className="font-Yanone ml-2">ver em outra loja</u></Link>
                      </p>
                    </div>
                  </div>
                  <div className="d-lg-none d-flex justify-content-center">
                    <img src={iconLocal} alt="Icone de Localidade" className="lightMode mr-2" />
                    <img src={iconLocalDM} alt="Icone de Localidade" className="darkMode mr-2" />
                    <p className="font-size-12 font-Yanone"><b>{hasSelectedStore.name}</b> <Link to='/lojas/' className="text-dark bg-transparent border-0"><u className="font-Yanone ml-2">ver em outra loja</u></Link></p>
                  </div>
                </header>
                <div className={styles.swiperOfertas}>
                  <Swiper {...swiperParams}>
                    {offers.slice(0, 6).map((oferta, i) => {
                      return (
                        <div className={`p-3 position-relative d-flex align-items-end justify-content-center ${styles.offersImageHome}`} key={`A${i}`}>
                          <span className="position-absolute offer-home-final-date">Válida até {oferta.finishDate && moment.utc(oferta.finishDate).tz('America/Sao_Paulo').format('DD/MM/YYYY')}</span>
                          <GatsbyImage image={oferta.image.childImageSharp.gatsbyImageData} className="shadow dia-rounded p-1 w-100 bg-ofertas" alt={`Oferta DIA Válida até ${oferta.finishDate}`} />
                        </div>
                      )
                    })}
                  </Swiper>
                </div>
                <div className="d-flex justify-content-center mt-3 ">
                  <Link to={`/lojas/${hasSelectedStore.slug}/`} id="view-all-offer"><button type="button" className="btn bg-light-green text-white w-100 font-size-1125 py-2">Ver Todas as Ofertas</button></Link>
                </div>
              </>
            ) : (
              <>
                {findStore &&
                  <header className="pb-4 px-4 d-grid justify-content-center align-items-center">
                    <h2 className="text-center text-red font-size-32px">
                      Não há ofertas nesta loja!
                    </h2>
                    <p className="mb-3 font-size-15 text-center">Escolha outra loja para ver ofertas da Semana.</p>
                    <button className="btn btn-red-1 d-flex justify-content-center mx-auto" onClick={() => setFindStore(true)}>
                      <img src={Ilojas} className="w-10 pr-1" style={{ filter: 'invert(1)' }} alt="icone de Loja" />
                      Buscar outra loja
                    </button>
                  </header>
                }
                {findStore &&
                  <OfertasSearch lojas={data.lojas} />
                }
              </>
            ) : (
              <>
                <OfertasSearch lojas={data.lojas} handleTabloide={handleTabloide} setHasSelectedStore={setHasSelectedStore} />
              </>
            )
        }
      </section>
    </>
  )
}

const OfertasSearch = ({ lojas, handleTabloide, setHasSelectedStore }) => {
  const [markets, setMarkets] = useState(lojas.nodes.map(node => (node)))
  const [searchLabel, setSearchLabel] = useState(false)
  const [displaySearchContainer, setDisplaySearchContainer] = useState(false)

  const [itemsToShow, _setItemsToShow] = useState(10)
  const itemsToShowRef = React.useRef(itemsToShow)
  const setItemsToShow = data => {
    itemsToShowRef.current = data
    _setItemsToShow(data)
  }

  const path = globalHistory.location.pathname

  function onSuccess (position) {
    const pos = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }
    console.log('success')
    calcDistance(pos)
  }

  function onError (error) {
    var txt
    switch (error.code) {
      case error.PERMISSION_DENIED:
        txt = 'Location permission denied'
        break
      case error.POSITION_UNAVAILABLE:
        txt = 'Location position unavailable'
        break
      case error.TIMEOUT:
        txt = 'Location position lookup timed out'
        break
      default:
        txt = 'Unknown position.'
    }
    console.error(txt)
  }

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?language=pt-BR&libraries=places&key=${process.env.GATSBY_CREDENTIAL_API_MAPS_LOJAS}`
      const insercao = document.getElementsByTagName('script')[0]
      insercao.parentNode.insertBefore(script, insercao)
      script.addEventListener('load', e => {
        console.log('Geocoder is now available!')
      })
    }

    if (document.querySelector('#search-container-home')) {
      document.querySelector('#search-container-home').addEventListener('scroll', handleInfinityScroll)
    }
    enableScroll()
  })

  const enableScroll = () => {
    document.getElementsByTagName('html')[0].style.overflow = 'auto'
    document.getElementsByTagName('html')[0].style.position = 'relative'
    document.getElementsByTagName('html')[0].style.width = '100%'
  }

  const handleInfinityScroll = (event) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.target
    const delta = Math.round(scrollTop + offsetHeight)
    if (delta >= scrollHeight - 5) {
      setItemsToShow(itemsToShowRef.current + 10)
    }
  }

  const useCurrentLocation = () => {
    setDisplaySearchContainer(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError)
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }

  const calcDistance = (p) => {
    console.log('>> CalcDistance')
    const myLat = p.lat
    const myLng = p.lng
    let mkts = lojas.nodes.map(node => {
      const LatLoja = node.lat
      const LngLoja = node.lng
      const radlat1 = (Math.PI * myLat) / 180
      const radlat2 = (Math.PI * LatLoja) / 180

      const theta = myLng - LngLoja
      const radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      dist = dist * 1.609344
      node.distance = parseFloat(dist.toFixed(1))
      return node
    })
    mkts = mkts.sort((a, b) => (a.distance - b.distance))
    setMarkets(mkts)
  }

  const resetInfinityScroll = () => {
    setItemsToShow(10)
    document.querySelector('#search-container-home').scrollTop = 0
  }

  const handleSearch = (e) => {
    setDisplaySearchContainer(true)
    e.preventDefault()

    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({
      address: e.target[0].value,
      region: 'br',
      componentRestrictions: {
        country: 'BR'
      }
    },
    (results, status) => {
      console.log('google results', { results: results, status: status })

      if (status === 'OK') {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        }

        setSearchLabel(results[0].formatted_address)
        calcDistance(pos)
        resetInfinityScroll()
      } else {
        console.log('FAIL')
      }
    })
  }

  const handleMarketSelected = (market) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('currentMarket', JSON.stringify(market))
      document.getElementById('currentMarket').innerHTML = market.Nome

      if (path.match(/\/lojas\/[a-z]/)) {
        navigate(`/lojas/${market.Slug}`)
      }
      const currentStore = typeof window !== 'undefined' ? window.localStorage.getItem('currentMarket') : ''
      const currentMarket = currentStore ? JSON.parse(currentStore) : ''
      setHasSelectedStore(currentMarket)
      handleTabloide(currentMarket)
    }
  }

  return (
    <div className='mx-2'>
      <div className="row justify-content-center mt-5">
        <div className="col-lg-12">
          <h2 className="text-center text-red font-size-32px"> Ofertas da Semana  </h2>
          <div className='d-flex justify-content-center align-items-center'>
            <img src={iconPin} alt='Ícone Pin' width='19' height='27' />
            <span className="font-size-17 font-Yanone text-center pl-2">Encontre as ofertas da loja mais próxima de você, por favor, identifique a sua região</span>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-3">
        <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
          <form onSubmit={() => handleSearch(event)} className='w-100 font-size-1'>
            <label htmlFor="input-search"></label>
            <input type="text" name="input-search" id="input-search" className="text-center inputLoja pt-2 pb-2 pr-5 pl-3 bg-white text-dark" placeholder="Digite seu CEP, Cidade ou Bairro" />
            <button type="submit" name="button" className="border-0 bg-transparent outline-0" aria-label="search-button">
              <img src={lupa} className="imgLupa position-absolute" alt="search icon" />
            </button>
          </form>
          <span className='font-size-17 font-Yanone'>ou</span>
        </div>
      </div>

      <div className="row d-flex justify-content-center align-items-center mb-3">
        <div className="col-md-4">
          <button className="btn btn-red-1 btn-block text-white font-size-1" onClick={() => useCurrentLocation()}>
            <FontAwesomeIcon icon={faMapMarked} className="fa-icon-button" />
            Usar minha localização atual
          </button>
        </div>
        <div className="col-md-12">
          {
            searchLabel && (
              <div className="w-65 mx-auto">
                <h4 className="paragrafoLojas text-center font-size-1 mt-4">Resultados de Busca para "{searchLabel}"</h4>
              </div>
            )
          }
        </div>
      </div>
      {displaySearchContainer &&
        <div className="row">
          <div className="col-12">
            <div id="search-container-home">
              {markets.slice(0, itemsToShow).map(market => {
                console.log('oooooooooo', market.name)
                return (
                  <div className="lojasCard mt-2 mr-sm-2" key={`A${market.storeNumber}`}>
                    <div className="row align-items-center">
                      <div className="col-lg-9 col-md-12 ">
                        <p className="font-weight-bold">{market.name}</p>
                        {market.distance && <p>Distância: {market.distance} Km</p>}
                        <p>{market.address} <br />
                          {market.district} - {market.city} - {market.uf}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-12">
                        <button type="button" className="btn btn-red-1 w-100 py-2" onClick={() => handleMarketSelected(market)}>
                          Selecionar
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <Link to='/lojas/' className="btn btn-red-1 font-Yanone w-100 py-2 mt-3">
              Ver Todas as Lojas
            </Link>
          </div>
        </div>
      }
    </div>
  )
}

const TemNoDia = () => {
  const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      1000: {
        slidesPerView: 5,
        spaceBetween: 1
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 1
      }
    }
  }

  return (
    <>
      <section className={`container my-5 ${styles.swiperHome}`}>
        <header className="pb-4 d-flex justify-content-center align-items-center">
          <h2 className="text-center text-red" tabIndex="0">
            Tem no <span className="font-weight-bold">Dia</span>
          </h2>
        </header>
        <Swiper {...swiperParams}>
          <div className="temNoDiaItems position-relative d-flex justify-content-center align-items-center">
            <p className={`position-absolute text-blue font-size-1 font-weight-bold ${styles.temNoDiaItemText}`} >Padaria</p>
            <img src={padaria} alt="No DIA tem Padaria" className={styles.temNoDiaSize}/>
          </div>
          <div className="temNoDiaItems position-relative d-flex justify-content-center align-items-center">
            <p className={`position-absolute text-blue font-size-1 font-weight-bold ${styles.temNoDiaItemText}`} >Frutas e Legumes</p>
            <img src={frutas} alt="No DIA tem Frutas"className={styles.temNoDiaSize}/>
          </div>
          <div className="temNoDiaItems position-relative d-flex justify-content-center align-items-center">
            <p className={`position-absolute text-blue font-size-1 font-weight-bold ${styles.temNoDiaItemText}`} >Carnes Frescas</p>
            <img src={carnes} alt="No DIA tem Carnes"className={styles.temNoDiaSize}/>
          </div>
          {/* <div className="temNoDiaItems position-relative d-flex justify-content-center align-items-center">
            <p className={`position-absolute text-blue font-size-1 font-weight-bold ${styles.temNoDiaItemText}`} >Mercearia</p>
            <img src={mercearia} alt="No DIA tem Mercearia"className={styles.temNoDiaSize}/>
          </div> */}
          <div className="temNoDiaItems position-relative d-flex justify-content-center align-items-center">
            <p className={`position-absolute text-blue font-size-1 font-weight-bold ${styles.temNoDiaItemText}`} >Produtos DIA</p>
            <img src={produtosDia} alt="No DIA tem Produtos DIA"className={styles.temNoDiaSize}/>
          </div>
          <div className="temNoDiaItems position-relative d-flex justify-content-center align-items-center">
            <p className={`position-absolute text-blue font-size-1 font-weight-bold ${styles.temNoDiaItemText}`} >Receitas do Dia</p>
            <img src={receitasTodoDia} alt="No DIA tem Produtos DIA"className={styles.temNoDiaSize}/>
          </div>
        </Swiper>
      </section>
    </>
  )
}

const DiaFavorito = (data) => {
  return <>
    <section>
      <div className="container">
        <div className="row d-block d-lg-flex mx-2">
          <div className="col p-0 d-lg-block d-none">
            <GatsbyImage
              image={data.fav1.childImageSharp.gatsbyImageData}
              className={`w-100 ${styles.imgFav1}`}
              alt="mercado do dia" />
          </div>
          <div className="col p-0 d-lg-none d-block">
            <GatsbyImage
              image={data.favMobile.childImageSharp.gatsbyImageData}
              className={`w-100 ${styles.imgFavMobile}`}
              alt="mercado do dia" />
          </div>
          <div className="col p-0 position-relative d-flex align-items-center justify-content-center">
            <div className="position-absolute above mx-3">
              <h2 className="text-white font-size-15 text-center d-lg-block d-none font-size-2 mb-4">Já encontrou seu mercado Dia favorito?</h2>
              <h2 className="text-white font-size-15 text-center d-lg-none d-md-block font-size-25 mb-4">Já encontrou seu<br /> mercado Dia favorito?</h2>
              <Link className="font-Yanone text-dark" style={{ textDecoration: 'none' }} to="/lojas/">
                <button className="btn btn-red-1 d-flex justify-content-center align-items-center mx-auto py-lg-2 p-3 font-size-12 w-85">
                  <img src={Ilojas} className="w-10 pr-1" style={{ filter: 'invert(1)' }} alt="icone Lojas" />
                  Localize a loja mais próxima
                </button>
              </Link>
            </div>
            <GatsbyImage
              image={data.fav2.childImageSharp.gatsbyImageData}
              className={`w-100 ${styles.imgFav2}`}
              alt="background mapa" />
          </div>
        </div>
      </div>
    </section>
  </>
}

const BannerAppNew = (data) => {
  return <>
    <section>
      <div className="container-fluid p-0">
        <GatsbyImage
          image={data.bannerAppNew.childImageSharp.gatsbyImageData}
          className="w-100 d-md-flex d-none"
          alt="banner app dia" />
        <GatsbyImage
          image={data.bannerAppMobileNew.childImageSharp.gatsbyImageData}
          className="w-100 d-md-none d-flex"
          alt="banner app mobile dia" />
      </div>
    </section>
  </>
}

// const BannerApp = (data) => {
//   const image = getImage(data.backgroundPhone.childImageSharp.gatsbyImageData)
//   const bgImage = convertToBgImage(image)
//   return <>
//     <section className={styles.customBannerAppMargin}>
//       <BackgroundImg
//         Tag="div"
//         {...bgImage}
//         preserveStackingContext
//         className={`d-lg-flex d-none ${styles.customBanner}`}
//       >
//         <div className="container W-100" style={{ minHeight: 380 }}>
//           <div className="row h-100 position-relative">
//             <div className="col d-grid align-items-center justify-content-center">
//               <div className="h-100">
//                 <div className="position-relative d-flex justify-content-center h-50">
//                   <div className="d-grid justify-content-center position-relative pt-4">
//                     <h2 className="text-white font-size-3 mb-0 position-absolute pt-4 text-center w-100 z-index-1"><b>Meu Desconto</b></h2>
//                     <img src={logoDesconto} className="w-100 mx-auto position-relative" alt="logo desconto" />
//                   </div>
//                 </div>
//                 <p className="text-white font-size-2 py-4 text-center above position-relative font-Yanone ">O app que vale Dinheiro</p>
//                 <Link to="/clubdia/">
//                   <button className="btn bg-dia-green text-white p-2 w-100 py-3 font-size-12">Veja como funciona</button>
//                 </Link>
//               </div>
//             </div>

//             <div className="col d-flex justify-content-between align-items-center pl-0">
//               <GatsbyImage
//                 image={data.celular.childImageSharp.gatsbyImageData}
//                 className={`w-85 position-absolute ${styles.customPhonePosition}`}
//                 alt="Telefone APP DIA" />
//               <img src={cupom} className={`position-absolute ${styles.customCupomPosition}`} alt="Cupom de desconto APP DIA" />
//             </div>

//             <div className="col d-flex align-items-center justify-content-end  text-center w-100">
//               <div className="">
//                 <p className="font-size-15 font-weight-bold text-green-1">Baixe Agora <br />
//                   <span className="text-white">e economize</span> <br />
//                   <span className="text-white">ainda mais</span>
//                 </p>
//                 <div className="d-grid">
//                   <a href="https://play.google.com/store/apps/details?id=br.com.dia.customers&referrer=utm_source%3Dsite%26utm_medium%3Dbutton%26utm_campaign%3Ddownloadapp" target="_blank" rel="noopener noreferrer"><img src={gogolePlay} className={`mb-3 position-relative ${styles.iconsImage}`} width={120} height={40} alt="Baixe o APP do DIA na Play Store" /></a>
//                   <a href="https://click.google-analytics.com/redirect?tid=UA-26046188-5&url=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fmeu-desconto-clubdia%2Fid1311617705%3Fmt%3D8&aid=br.com.dia.customers&idfa=%{idfa}&cs=site&cm=button&cn=downloadapp" target="_blank" rel="noopener noreferrer" ><img src={appStore} className={`${styles.iconsImage} position-relative`} width={120} height={40} alt="Baixe o APP do DIA na APP Store" /></a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </BackgroundImg>

//       <div className="container">
//         <BackgroundImg
//           Tag="div"
//           {...bgImage}
//           preserveStackingContext
//           className={'d-lg-none d-block vh-70 mx-2 dia-rounded p-4 overflow-hidden'}
//           // fluid={data.backgroundPhone.childImageSharp.fluid}
//         >
//           <div style={{ minHeight: 540 }}>
//             <div className="position-relative">
//               <h2 className="text-white font-size-3"><b>Meu Desconto</b> <br /> Club <b>Dia</b></h2>
//               <p className="font-size-15 text-white">O app que <br /> vale dinheiro</p>
//               <div className={styles.phoneBannerAppMobile}>
//                 <img src={phone} className="w-85 shadow-lg position-relative" alt="Telefone APP DIA" />
//               </div>
//               <div>
//                 <img src={cupom} className="w-85 position-relative" alt="Cupom de desconto APP DIA" />
//               </div>
//             </div>

//             <div className={styles.buttonBannerAppMobile}>
//               <Link to="/clubdia/" className="w-85">
//                 <button className="btn bg-dia-green text-white p-2 btn-block font-size-15 py-2">Veja como funciona</button>
//               </Link>
//             </div>
//           </div>
//         </BackgroundImg>
//       </div>
//     </section>
//   </>
// }

const Index = ({ data }) => {
  const currentStore = typeof window !== 'undefined' ? window.localStorage.getItem('currentMarket') : ''
  const currentMarketJson = currentStore ? JSON.parse(currentStore) : ''
  const [currentMarket, setCurrentMarket] = useState(currentMarketJson)
  const modalRef = useRef()
  const breadC = {
    visible: false,
    color: 'dark', // Options dark || white
    items: [
      { path: '', name: '' }
    ]
  }

  return (
    <>
      <Layout breadCrumb={breadC} homeCallback={setCurrentMarket} ref={modalRef} >
        <SEO />
        <Banner />
        <OfertasHome current={currentMarket} tabloide={data.tabloide} modal={modalRef} lojas={data.lojas} />
        {/* <BannerDiaExpress banner={data.bannerWppDia} bannerMobile={data.bannerWppDiaMobile} colStyle={'mx-2'} /> */}
        <TemNoDia />
        <DiaFavorito fav1={data.favorito1} favMobile={data.favoritoMobile} fav2={data.favorito2} />
        <SectionReceitas />
        <BannerAppNew bannerAppNew={data.bannerAppNew} bannerAppMobileNew={data.bannerAppMobileNew} />
        {/* <BannerApp celular={data.phone} backgroundPhone={data.backgroundPhone} /> */}
        {data.posts.edges.length > 0 && <InstaSection posts={data.posts} />}
      </Layout>
    </>
  )
}

export default Index

export const query = graphql`{
  lojas: allCloudOfertasLoja {
    nodes {
      id
      slug
      storeNumber
      address
      cep
      city
      district
      lat
      lng
      name
      reference
      status
      uf
      whatsapp
      mondayOpen
      mondayClose
      holidayOpen
      holidayClose
      sundayOpen
      sundayClose
      services {
        icon
        name
      }
      tariff {
        id
        name
      }
    }
  }
  posts: allInstaPost(limit: 6) {
    edges {
      node {
        permalink
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, width:500, height:500 layout: CONSTRAINED)
          }
        }
      }
    }
  }
  tabloide: allCloudOfertasTabloide {
    nodes {
      id
      tariff {
        id
        name
      }
      offer {
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        format
        finishDate
      }
    }
  }
  backgroundPhone: file(relativePath: {eq: "home/banner-app/bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  phone: file(relativePath: {eq: "home/banner-app/celular.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 315, height: 409, layout: CONSTRAINED)
    }
  }
  favorito1: file(relativePath: {eq: "home/dia-favorito-1.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 585, height: 240, layout: CONSTRAINED)
    }
  }
  favoritoMobile: file(relativePath: {eq: "home/favoritoMobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 330, height: 275, layout: CONSTRAINED)
    }
  }
  favorito2: file(relativePath: {eq: "home/dia-favorito-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 585, height: 240, layout: CONSTRAINED)
    }
  }
  bannerAppNew: file(relativePath: {eq: "home/banner-app/bannerApp.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerAppMobileNew: file(relativePath: {eq: "home/banner-app/bannerAppMobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerWppDia: file(relativePath: {eq: "home/banner-desktop-whatsapp.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerWppDiaMobile: file(
    relativePath: {eq: "home/banner-mobile-whatsapp.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  lojas: allCloudOfertasLoja {
    nodes {
      id
      slug
      storeNumber
      address
      cep
      city
      district
      lat
      lng
      name
      reference
      status
      uf
      whatsapp
      mondayOpen
      mondayClose
      holidayOpen
      holidayClose
      sundayOpen
      sundayClose
      services {
        icon
        name
      }
      tariff {
        id
        name
      }
    }
  }
}
`
